<template>
    <el-scrollbar class="login-container">
        <div class="login-wrapper">
            <div class="login-main">
                <div class="login-right">
                    <div class="title">{{appName}}</div>
                    <div class="title-text">{{titleText}}</div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="top">
                        <el-form-item label="用户名" prop="name">
                            <el-input v-model="ruleForm.name" class="input-student" placeholder="请输入用户账号"
                                      @keydown.native.enter="submitForm('ruleForm')">
                                <i slot="prefix" class="iconfont">&#xea3d;</i>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="ruleForm.password" class="input-student" placeholder="请输入密码"
                                      type="password" @keydown.native.enter="submitForm('ruleForm')">
                                <i slot="prefix" class="iconfont">&#xe82e;</i>
                            </el-input>
                        </el-form-item>

                        <el-form-item style="margin-top: 40px">
                            <el-button class="login-button" round type="primary" @click="submitForm('ruleForm')"
                                       style="width: 380px">登录
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import {login} from '@/utils/apis'
    import config from "@/utils/config";

    export default {
        name: "Login",
        data() {
            return {
                appName: config.appName,
                titleText: 'ZHIBODIANSHANGYUNYING',
                ruleForm: {
                    name: '',
                    password: '',
                },
                rules: {
                    name: [
                        {required: true, message: '请输入用户账号', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ],
                }
            }
        },
        mounted() {
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('username', this.ruleForm.name)
                        formData.append('password', this.ruleForm.password)
                        login(formData).then(res => {
                            localStorage.setItem('role', res.data.role)
                            if (res.data.role === 5) {
                                localStorage.setItem('studentToken', res.data.token);
                                localStorage.setItem('nickname', res.data.nickname);
                                localStorage.setItem('studentAvatar', res.data.avatar);
                                localStorage.setItem('studentId', res.data.id)
                                localStorage.setItem('host_url', res.data.host_url)
                                localStorage.setItem('train_id', res.data.train_info.train_id)
                                localStorage.setItem('scriptId', res.data.train_info.script_id);
                                localStorage.setItem('train_name', res.data.train_info.train_name);
                                localStorage.setItem('paper_id', res.data.train_info.paper_id);
                                localStorage.setItem('class_id', res.data.train_info.class_id);
                                localStorage.setItem('start_time', res.data.train_info.start_time);
                                localStorage.setItem('end_time', res.data.train_info.end_time);
                                localStorage.setItem('advert_id', res.data.train_info.advert_id);
                                localStorage.setItem('websocket_host', res.data.websocket_host);
                                this.$message.success({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1000
                                })
                                this.$router.push('/exam/index')
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .login-container {
        width: 100vw;
        height: 100vh;

        & > ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .login-wrapper {
            background: url("../assets/image/login_student.png") no-repeat center center;
            background-size: cover;
            min-width: 1100px;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .login-main {
        width: 100%;
        margin-left: 200px;
        overflow: hidden;
        display: flex;

        .login-left {
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .login-right {
            width: 1%;
            flex: 1;

            .title {
                font-size: 48px;
                font-family: BigruixianRegularGB2;
                color: #FFFFFF;
            }

            .title-text {
                font-size: 24px;
                font-family: BigruixianRegularGB2 .0;
                font-style: italic;
                color: #FFFFFF;
                margin-top: 20px;
                letter-spacing: 8px;
            }

            .demo-ruleForm {
                margin-top: 90px;

                .el-form-item__content {
                    color: #fff;
                }
            }

            ::v-deep .el-form-item__label {
                color: #fff;
                font-family: Microsoft YaHei;
                opacity: 0.6;

                &:before {
                    content: '';
                }
            }
        }
    }

    ::v-deep.input-student {

        .el-input__inner {
            background-color: #070932;
            padding-left: 60px;
            border-top: none;
            border-left: none;
            border-right: none;
            width: 520px;
            color: #fff;
            font-size: 16px;
        }

        .el-input__prefix {
            left: 10px;
        }

        .iconfont {
            color: #fff;
            font-size: 20px;
        }

        :-webkit-autofill {
            -webkit-text-fill-color: #fff;
            transition: background-color 50000s ease-in-out 0s;
        }
    }

    .login-button {
        background: #4B45FF;
    }
</style>
